import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "mission-statement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mission-statement",
        "aria-label": "mission statement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mission Statement`}</h2>
    <h3 {...{
      "id": "mission",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mission",
        "aria-label": "mission permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mission`}</h3>
    <p>{`Pechersk School International is a diverse `}<strong parentName="p">{`learning community`}</strong>{` which provides an `}<strong parentName="p">{`academically challenging programme`}</strong>{` in a `}<strong parentName="p">{`student-centred environment`}</strong>{`.`}</p>
    <p>{`As an IB world school, PSI is committed to developing well-rounded, `}<strong parentName="p">{`global citizens`}</strong>{` by promoting `}<strong parentName="p">{`personal excellence`}</strong>{` in the classroom and beyond.  `}</p>
    <Table fullWidth innerWidth={800} mdxType="Table">
      <table>
    <colgroup>
        <col style={{
            "width": "18%"
          }} />
        <col style={{
            "width": "36%"
          }} />
        <col style={{
            "width": "44%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>OUR MISSION</strong></th>
        <th><strong>DEFINITION</strong></th>
        <th><strong>LOOKS LIKE….</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Learning Community</strong></td>
        <td>A spirit of collaboration pervades the school, with all members of the school community committed to student
            success and wellbeing as well as to their own lifelong learning.
        </td>
        <td>
            <ul>
                <li><p>Students, teachers, staff and families working together to ensure student success</p>`</li>
                <li><p>Respectful and positive interactions among all shareholders</p>`</li>
                <li><p>A focus on learning for all members of the community</p>`</li>
            </ul>
        </td>
    </tr>
    <tr className="even">
        <td><strong>Academically challenging programme</strong></td>
        <td>PSI is an IB World School offering all three programmes for students EC - Grade 12. Students are prepared to attend universities that match their needs and abilities.
        </td>
        <td>
            <ul>
                <li><p>Written, taught and assessed curriculum standards based on current best practices</p></li>
                <li><p>An IB curriculum framework</p></li>
                <li><p>Frequent internal and external, formative and summative assessment</p></li>
                <li><p>Mother tongue programme</p></li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Student-centered environment</strong></td>
        <td>Students engage in a wide variety of activities and experiences that promote powerful learning along with increased self-confidence, personal empowerment, and the freedom to explore personal passions.
        </td>
        <td>
            <ul>
                <li><p>An IB inquiry approach with the students at the center of the learning</p></li>
                <li><p>Extensive libraries and IT resources that support a range of topics and interests</p></li>
                <li><p>A Secondary School ‘Bring Your Own Device’ programme to connect students to a wider world</p></li>
                <li><p>Support for student-initiated action</p></li>
            </ul>
        </td>
    </tr>
    <tr className="even">
        <td><strong>Global citizens</strong></td>
        <td>PSI students and community members are global citizens, empowered to recognise and act on issues of importance in their local and international communities.
        </td>
        <td>
            <ul>
                <li><p>Appreciate local and international cultures</p></li>
                <li><p>Engage positively with students within and beyond their own nationality/background</p></li>
                <li><p>Accept and understand their own and other cultures</p></li>
                <li><p>Listen to others and respect opinions/perspectives</p></li>
                <li><p>Express opinions respectfully</p></li>
                <li><p>Take positive action on issues of local, national and global importance</p></li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Personal excellence</strong></td>
        <td>At PSI, students work with teachers to define their own standards for excellence both in academics and areas of passion and work to achieve those.
        </td>
        <td>
            <ul>
                <li><p>Student goal-setting</p></li>
                <li><p>Student led conferences and portfolios</p></li>
                <li><p>Student-Parent-Teacher Conferences</p></li>
                <li><p>Standards based reporting</p></li>
                <li><p>A variety of external and internal assessments, as well as self-assessment and                     reflection</p></li>
                <li><p>Recognizing high achievement as well as personal effort</p></li>
                <li><p>Ukrainian Externum Programme</p></li>
                <li><p>Academic Honesty Policy</p></li>
                <li><p>PYP Exhibition, MYP Personal Projects, DP Extended Essay</p></li>
                <li><p>Sports competitions, theatrical and musical performances, Model United Nations, Maths                     Counts, Destination Imagination, and other opportunities that showcase student talent</p>>
                </li>
                <li><p>Placements at top rate universities around the world</p></li>
            </ul>
        </td>
    </tr>
    </tbody>
      </table>
    </Table>
    <h3 {...{
      "id": "vision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vision",
        "aria-label": "vision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vision`}</h3>
    <p>{`We grow. We lead. We succeed.`}</p>
    <h3 {...{
      "id": "motto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motto",
        "aria-label": "motto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motto`}</h3>
    <p>{`A place where we belong.`}</p>
    <h3 {...{
      "id": "psi-core-values",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#psi-core-values",
        "aria-label": "psi core values permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PSI Core Values`}</h3>
    <Table fullWidth innerWidth={800} mdxType="Table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`INTEGRITY, HONESTY, TRUST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`We value acting on strong moral principles that build trust within our community.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`RESPECT, EMPATHY, TOLERANCE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`We value respect and understanding for each other’s individualities, cultures, beliefs, ideas and needs.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`DIVERSITY, EQUITY, INCLUSION`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`We value a diverse community where everyone is safe, included, appreciated, and feels free to be themselves.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`HAPPINESS, JOY`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`We value a joyful environment that promotes a happy and motivated community.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PASSION`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`We value creativity, inspiration and passion in everything we do.`}</td>
          </tr>
        </tbody>
      </table>
    </Table>
### PSI’s Definition of Learning
    <p>{`PSI is an IB World School that defines learning as a holistic, purposeful, learner-centred and inquiry-based process. At PSI, learners engage in a personalised journey of discovery, reflection and action, which is motivated by their passions and interests. Recognising that learning is limitless, individuals collaboratively build their knowledge, skills and understanding in academics, arts, athletics, and service, with the well-being of themselves and the wider global community in mind.`}</p>
    <h2 {...{
      "id": "ib-mission-statement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ib-mission-statement",
        "aria-label": "ib mission statement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IB Mission Statement`}</h2>
    <p>{`The International Baccalaureate aims to develop inquiring, knowledgeable and caring young people who help create a better and more peaceful world through intercultural understanding and respect.`}</p>
    <p>{`To this end, the organization works with schools, governments and international organizations to develop challenging programmes of international education and rigorous assessment.`}</p>
    <p>{`These programmes encourage students across the world to become active, compassionate and lifelong learners who understand that other people, with their differences, can also be right.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><em parentName="th">{`PSI Kyiv is committed to the safety and well-being of all children and young people in our care. We support the rights of the child and will act without hesitation to ensure a child-safe environment. We also support the rights and well-being of our staff and volunteers and encourage their active participation in building and maintaining a secure environment for all participants.`}</em></th>
        </tr>
      </thead>
      <tbody parentName="table">
      </tbody>
    </table>
    <h3 {...{
      "id": "parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#parameters",
        "aria-label": "parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameters`}</h3>
    <p>{`PSI:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Maintains its status as a mid-sized IB world day school (not to exceed 600 students) that provides an international education for students aged 3 - 18`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Aligns school enrolment, facilities, finances, resources and capacity to ensure sustainability`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintains the international identity of the school whilst respecting Ukrainian culture`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uses English as the main language of instruction, whilst demonstrating respect of mother tongue languages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Approves and maintains programmes that promote the overall well-being of its students`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensures students' best interests are at the centre of all decision-making`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Enrols students and families, and employs staff who accept and exemplify the school’s mission, vision and core values`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Only accepts behaviour, from all community members, that ensures the safety, integrity, and dignity of all individuals`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hires and retains qualified and flexible staff members who are committed to ongoing professional growth`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Expects direct parent involvement in the lives of their children at school`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "diversity-statement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#diversity-statement",
        "aria-label": "diversity statement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Diversity Statement:`}</h2>
    <p>{`PSI recognises the responsibility of nurturing a diverse, equitable, and inclusive global society. Together we aspire to create a harmonious community where each person feels that their whole identity is known and respected. We commit to persevering together as we face the inevitable challenges in our ever-evolving journey to create a just and peaceful world.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      